interface FormData {
  [key: string]: string | boolean;
}

interface ValidationErrors {
  [key: string]: boolean;
}

export default function useInputValidation(formData: FormData) {
  const validationInputErrors = ref<boolean>(false);
  const validationErrors = ref<ValidationErrors>({});
  const isValid = computed(() => {
    const isNotFullFormData = Object.keys({ ...formData }).some(
      (key) => !formData[key]
    );
    return !isNotFullFormData && !validationInputErrors.value;
  });
  function isInputError(errorBug: { label: string; error: boolean }) {
    validationErrors.value[errorBug.label] = errorBug.error;
    for (const key in validationErrors.value) {
      if (validationErrors.value[key]) {
        validationInputErrors.value = true;
        return;
      } else {
        validationInputErrors.value = false;
      }
    }
  }

  return {
    validationInputErrors,
    isInputError,
    isValid,
  };
}
