export const phoneNumberFormat = {
  by: {
    countryFormat: /^(\+)?((?:375)(?:25|29|33|44)\d{7})$/,
    errorMessage: "Invalid number format. Valid format: +375 00 000 00 00",
    placeholder: "+375 00 000 00 00",
  },
  cy: {
    countryFormat: /^357\d{8}$/,
    errorMessage: "Phone format - country code 357 - 11 of digits",
    placeholder: "357***********",
  },
};

export default (countryCode: "by" | "cy") =>
  (inputVal: number | string): null | string => {
    const val = inputVal.toString();

    if (
      val.length > 0 &&
      !phoneNumberFormat[countryCode].countryFormat.test(val)
    ) {
      return phoneNumberFormat[countryCode].errorMessage;
    }

    return null;
  };
